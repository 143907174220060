<!-- TODO: set Account, OtherCategory to come from same component? -->
<template>
    <div>
        <h1>Setup Accounts</h1>
        <v-row style="padding-right:20px">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
            >
                Save
            </v-btn>
        </v-row>
        <br>
        <v-divider></v-divider>
        <br>
        <v-row justify="center">
            <div style="text-align:center">
                Setup your accounts below. Add any bank account, <br>
                credit card, or loan for which you want to actively <br> track
                the account balance.
            </div>
        </v-row>
        <DataTableCRUD
            v-model="accountList"
            title="Accounts"
            :headers=headers
            itemName="Account"
            :defaultItem=defaultItem
            v-on:saveItem="saveItem"
            v-on:deleteItem="deleteItem"
        >
            <template v-slot:form="slotProps">
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="slotProps.editedItem.name" label = "Name">
                            </v-text-field>
                        </v-col>
                        <!-- TODO: how to make bank a "select"/create input? -->
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                              label="Bank"
                              v-model="slotProps.editedItem.bank.bankid"
                              :items="bankList"
                              item-text="name"
                              item-value="bankid"
                          />
                        </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                            label="Type"
                            v-model="slotProps.editedItem.accounttype.accounttypeid"
                            :items="accountTypeList"
                            item-text="name"
                            item-value="accounttypeid"
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="slotProps.editedItem.shortname" label = "Short Name">
                        </v-text-field>
                      </v-col>
                    </v-row>
                </v-container>
            </template>

            <template v-slot:deleteTitle="slotProps">
                <div style="text-align:center; width:100%;">
                Are you sure you want to delete<br>"{{ slotProps.editedItem.name }}"?
                </div>
            </template>
        </DataTableCRUD>
      <v-dialog
          v-model="showNoDeleteDialog"
          v-on:click:outside="showNoDeleteDialog = false"
          max-width="500px"
      >
        <v-card
        >
          <v-card-title>Oops! We can't!!</v-card-title>
          <v-card-text>
            We have not enabled account deletions yet. Please contact us
            with the details about your request at
            <a href="mailto:support@budget.icu">support@budget.icu</a>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
                @click="showNoDeleteDialog = false"
              >OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import DataTableCRUD from '@/components/DataTableCRUD.vue'
import {Action, Getter} from '@/store/account/types';
import {Action as ProfileAction, Getter as ProfileGetter} from '@/store/profile/types'
export default {
    name: "Account",
    metaInfo: {
        title: 'Account',
    },
    components: {
        DataTableCRUD
    },
  mounted() {
    this.$store.dispatch(`${[ProfileAction.loadUserProfile]}`)
    this.$store.dispatch(`${[Action.loadAccountList]}`, {finpicid: this.activeFinpic.finpicid})
    this.$store.dispatch(`${[Action.loadBankList]}`)
    this.$store.dispatch(`${[Action.loadAccountTypeList]}`)
  },
  computed: {
    accountList() {
      return this.$store.getters[`${[Getter.getAccountList]}`]
    },
    bankList() {
      return this.$store.getters[`${[Getter.getBankList]}`]
    },
    accountTypeList() {
      return this.$store.getters[`${[Getter.getAccountTypeList]}`]
    },
    activeFinpic() {
      return this.$store.getters[`${[ProfileGetter.getActiveFinpic]}`]
    },
  },
  methods: {
    saveItem: function(event) {
      console.log(event)

      let accountData = {
        accountid: event.item.accountid,
        bankid: event.item.bank.bankid,
        accounttypeid: event.item.accounttype.accounttypeid,
        name: event.item.name,
        shortname: event.item.shortname,
        finpicid: this.activeFinpic.finpicid,
      }

      this.$store.dispatch(
          `${[Action.upsertAccount]}`,
          accountData
      )
    },
    deleteItem: function(event) {
      console.log(event)
      // TODO: need to check if an account is even deletable...?
      this.showNoDeleteDialog = true
    }
  },
  data: () => ({
    showNoDeleteDialog: false,
    headers: [
      {
          text: 'Name', value: 'name'
      },
      {
        text: 'Short Name', value: 'shortname'
      },
      {
          text: 'Bank', value: 'bank.name'
      },
      {
        text: 'Type', value: 'accounttype.name'
      },
      {
          text: 'Actions', value: 'actions'
      }
    ],
    defaultItem: {
      accountid: null,
      name: "",
      bank: {bankid: null, name: ""},
      accounttype: {accounttypeid: null, name: ""},
      shortname: "",
    },
  }),
}
</script>
