<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-select
            label="Account"
            :items="accountList"
            item-value="accountid"
            item-text="name"
            v-model="selectedAccount"
        />
        <!-- TODO: date selector -->
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateDisplay"
              label="Dates"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              />
          </template>
          <v-date-picker
              range
              v-model="dates"
              type="month"
              no-title
              @change="dateMenu = false"
          ></v-date-picker>
        </v-menu>
        <v-select
          label="Transaction Type"
          />
      </v-col>
      <v-col cols="6">
        <v-select
          label="Budget Category"
        />
        <v-select
          label="Tags"
          />
        <v-select
            label="Exclude Tags"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Overview</v-card-title>
          <v-card-title>
            <v-row>
              <v-col cols="4">
                <v-card>
                  <v-card-title>Count: {{ getCount }}</v-card-title>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card>
                  <v-card-title>Sum: {{ getSum }}</v-card-title>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card>
                  <v-card-title>Selected: {{ getSelectedSum }}</v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-progress-linear v-if="transactListRunning" indeterminate />
        <v-data-table
          ref="transactTable"
          :custom-filter="customFilter"
          :headers="headers"
          :items="transactList"
          :search="transactSearch"
          item-key="transactid"
          @current-items="updateData"
          v-on:click:row="selectTransactRow"
          >
          <template v-slot:top>
            <v-row>
              <v-spacer />
              <v-text-field
                label="Search"
                v-model="transactSearch"
                prepend-inner-icon="mdi-magnify"
                style="max-width:300px"
                />
            </v-row>
          </template>
        </v-data-table>
        <v-progress-linear v-if="transactListRunning" indeterminate />
        <v-dialog
            v-model="editTransactDialog"
            max-width="800px" persistent
            v-if="activeTransact && activeTransact.transactid"
        >
          <TransactForm
              :instanceId="null"
              :input-type="activeTransact.transactType"
              :id="activeTransact.transactid.toString()"
              :name="activeTransact.name"
              :description="activeTransact.description"
              :date="activeTransact.date"
              :datePost="activeTransact.datePost"
              :amount="activeTransact.amount"
              :tags="activeTransact.tags"
              :to-account="activeTransact.toAccount"
              :from-account="activeTransact.fromAccount"
              :transact-attr-link-id="activeTransact.primarytransactattrlink[0].transactattrlinkid.toString()"
              :budgets="activeTransact.budgets"
              :key="activeTransact.transactid"
              v-on:cancel="editTransactDialog = false"
              v-on:save="saveTransact"
              v-on:delete="deleteTransact"
          />
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {Action as AccountAction, Getter as AccountGetter} from '@/store/account/types'
import {Getter as ProfileGetter} from '@/store/profile/types'
import {Action, Action as TransactAction, Getter as TransactGetter} from '@/store/transact/types'
import {Action as BudgetAction} from "@/store/budgetTransact/types";
import {Getter as InstanceGetter} from '@/store/instance/types'
import TransactForm from '@/components/TransactForm.vue'
import Vue from 'vue'


export default {
  name: "Explorer",
  components: {
    TransactForm,
  },
  data: () => ({
    selectedAccount: null,
    dates: [],
    dateMenu: false,
    transactSearch: null,
    transactListFiltered: [],
    editTransactDialog: false,
    headers: [
      { text: 'Date', value: 'transactdate' },
      { text: 'Name', value: 'name' },
      { text: 'Description', value: 'description' },
      { text: 'Amount', value: 'amount' },
    ],
  }),
  computed: {
    accountList: function() {
      return this.$store.getters[`${[AccountGetter.getAccountList]}`]
    },
    transactList: function() {
      return this.$store.getters[`${[TransactGetter.GetExploreTransactList]}`]
    },
    getCount: function() {
      return this.transactListFiltered?.length
    },
    getSum: function() {
      const amts = this.transactListFiltered.map((elt) => elt.amount)
      return amts.reduce((prev, curr) => prev + curr, 0)
    },
    getSelectedSum: function() {
      return 0
    },
    transactListRunning: function() {
      return this.$store.getters[`${[TransactGetter.GetExploreTransactListRunning]}`]
    },
    dateDisplay: function() {
      return this.dates[0] + " - " + this.dates[1]
    },
    activeTransact: function() {
      return this.$store.getters[`${[TransactGetter.GetActiveTransact]}`]
    },
    activeInstance: function() {
      return this.$store.getters[`${[InstanceGetter.GetActiveInstance]}`]
    },
  },
  methods: {
    customFilter: function(value, search, item) {
      // TODO: import directly from vuetify...
      // import {defaultFilter} from "vuetify/src/util/helpers";
      let upstream = value != null &&
      search != null &&
      typeof value !== 'boolean' &&
      value.toString().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1

      const matchDate = this.matchesDates(item)
      return matchDate && upstream
    },
    matchesDates: function(item) {
      const current = new Date(item.date)
      const start = new Date(this.dates[0])
      const end = new Date(this.dates[1])
      return current >= start && current <= end
    },
    updateData: function() {
      this.$nextTick(() => {
        this.transactListFiltered = this.$refs.transactTable.$children[0].filteredItems
      })
    },
    setDefaultDates: function() {
      if (this.dates.length === 0) {
        Vue.set(this.dates,0,"2021-05")
        Vue.set(this.dates,1,"2021-09")
      }
    },
    selectTransactRow: function(arg1) {
      this.$store.dispatch(`${[TransactAction.ActivateTransact]}`, {transactid: arg1.transactid})
      this.editTransactDialog = true
    },
    saveTransact: function(transact) {
      // TODO - would be great to check for duplicates here...
      console.log(transact)
      this.$store.dispatch(`${[Action.UpsertTransact]}`, transact).then(
          () => {
            // refresh / update the list of budget transactions!
            this.$store.dispatch(
                `${[BudgetAction.BudgetTransactList]}`,
                {instanceid: this.activeInstance.instanceid}
            )
          })
      // TODO - catch errors!
      this.editTransactDialog = false
    },
    deleteTransact: function(transactid) {
      console.log(transactid)
      this.$store.dispatch(`${[Action.DeleteTransact]}`, {transactid: transactid}).then(
          () => {
            // refresh / update the list of budget transactions!
            this.$store.dispatch(
                `${[BudgetAction.BudgetTransactList]}`,
                {instanceid: this.activeInstance.instanceid}
            )
          }
      )
      // TODO - catch errors!
      this.dialog = false
      this.editTransactDialog = false
    },
  },
  watch: {
    selectedAccount: function(val) {
      this.$store.dispatch(`${[TransactAction.ExploreTransactList]}`, {accountid: val})
    },
  },
  mounted() {
    this.setDefaultDates()
    let finpic = this.$store.getters[`${[ProfileGetter.getActiveFinpic]}`]
    this.$store.dispatch(`${[AccountAction.loadAccountList]}`, {finpicid: finpic.finpicid})
  },
}
</script>

<style scoped>

</style>
